import * as React from "react"
import { navigate } from "gatsby"
import { Row, Col, Button, Container } from "react-bootstrap"
import DetailsLayout from "../components/details-layout"
import Seo from "../components/seo" 
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"

import Helmet from "react-helmet"
import { Carousel } from 'react-responsive-carousel';
import "./details.scss"
import  FaMapMarker  from "../images/faMapMarker";
import  FaBack  from "../images/faback";

import "react-responsive-carousel/lib/styles/carousel.min.css";


export const query = graphql`
query TourDetailsQuery($slug: String!)  {
    graphCmsItemTours(slug: { eq: $slug }) {
        id
        slug
        tourLocation
        tourPrice
        tourStatus
        tourTitle
        tourVideo
        tourUtilities {
          html
          raw
          text
        }
        tourIncludes {
          html
          raw
          text
        }
        tourImage {
          id
          imageAltText
          imageTitleText
          galleryImage {
            gatsbyImageData(placeholder: BLURRED, quality: 60)
            url
          }
        }
        tourGallery {
          id
          imageAltText
          imageTitleText
          galleryImage {
            gatsbyImageData(placeholder: BLURRED, quality: 60)
            url
          }
        }
        tourDuration {
          html
          text
          raw
        }
        tourDescription {
          html
          raw
          text
        }

     
    }
}`



const TourDetails = ({data, index}) => {
    const single = data.graphCmsItemTours;
    const options = {     
        caption: {
          showCaption: false
        }
    }
return (

    <DetailsLayout>
    <Seo
        title={single.tourTitle}
        description={single.tourDescription.text?.substring(0,155) || " "}
        image={single.tourImage.galleryImage.url}
    />
    <Helmet>
        <meta property='og:image' content={single.tourImage.galleryImage.url} />
        <meta property='twitter:image' content={single.tourImage.galleryImage.url} />
    </Helmet>

        <div className="listing-details">
            <div className="main-details">
                <div className="row">
                    <div className="col-sm-7">
                        
                        <ul div className="hero-buttons">
                        
                        <li class="back">
                                
                                <a id="primary-button" onClick={() => navigate(-1)}>
                               <FaBack />
                                 </a>
                               
                              
                            </li>
                         
                          

                       
                                    
                        </ul>

                        <div className="listing-gallery">
                           <Carousel options={options}>
                             {(single.tourGallery || []).map((imagesMap, id) => (
                                 
                                 <>
                                 <GatsbyImage image={imagesMap.galleryImage.gatsbyImageData} 
                                 quality={60}
                                 key={imagesMap.id}
                                 formats={["auto", "webp", "avif"]}
                                 alt={imagesMap.imageAltText}
                                 />
                                 
                                
                                 <img src={imagesMap.galleryImage.url} style={{ position: 'absolute'}}/>
                                 </>
                                  
                        
                               ))}
                           </Carousel>
                    
                        </div>
                                             
                    </div>
                

                    <div className="col-sm-5">
                        <div className="listing-info">
                            <div className="header-content">
                                <h1>{single.tourTitle}</h1>
                                <span className="location"><FaMapMarker /> {single.tourLocation}</span><br/>
                                {single.tourPrice && (
                                 <span className="price">
                                    <h4>{single.tourPrice}</h4>
                                 </span>
                                 )}
                            </div>
                        </div>
                        <div className="sticky-header">
                            <ul className="sticky-content">
                                <li>
                                    <a href="#data-table">
                                        Key Notes
                                    </a>
                                </li>
                                <li>
                                    <a href="#feature-chips">
                                        Description
                                    </a>
                                </li>
                                <li>
                                    <a href="#desc">
                                        Location
                                    </a>
                                </li>
                                <li>
                                    <a href="#location">
                                        Features
                                    </a>
                                </li>
                                <li>
                                    <a href="#contact-us">
                                        Book Now
                                    </a>
                                </li>
                            </ul>
                              
                            </div>
                        <div className="secondary-info">
                            {single.tourDescription && (
                            <div id="desc" className="description">
                                <div className="desc-main">
                                    <h2>Description</h2>
                                    {single.tourDescription.text} 
                                </div>
                            </div>
                            )}
                            <div id="location" className="map">
                                
                            
                            
                                
                              
                                
                                
                            </div>
                        

                            {single.tourVideo  && (
                            <div className="featured-video">
                                <iframe
                                    width="100%"
                                    height="300px"
                                    src={single.tourVideo}
                                    title="Featured Video"
                                    frameborder="0"
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                                </iframe>
                            </div>
                              )}
                            {single.tourUtilities && (
                            <div className="policies">
                                <div className="policies-main">
                                    <h2>What to Bring</h2>
                                    
                                    <div dangerouslySetInnerHTML={{ __html: single.tourUtilities.html }} /> 
                                   
                                    </div>
                            </div>
                             )}
                               {single.tourIncludes && (                           
                            <div className="disclaimer">
                                <div className="disclaimer-main">
                                    <h2>Tour Includes</h2>
                                  
                                    <div dangerouslySetInnerHTML={{ __html: single.tourIncludes.html }} /> 
                                    
                                    </div>
                            </div>
                            )}
                            {single.tourDuration && (
                            <div className="disclaimer">
                                <div className="disclaimer-main">
                                    <h2>Tour Duration</h2>
                                  
                                    <div dangerouslySetInnerHTML={{ __html: single.tourDuration.html }} /> 
                                    
                                    </div>
                            </div>
                            )}

                            <div className="disclaimer">
                           
                            
                             <div className="disclaimer-main">
                                    <h2>Book Now</h2>
                                  <p>Please fill in the below form to Book Tour.</p>
                                  <div class="elfsight-app-6fcbc23e-94fc-4b52-ac42-c19d0cfb5183"></div>
                    
                                    </div>
                            </div>
                       
                            <div className="disclaimer">
                           
                            
                                <div className="disclaimer-main">
                                  <h2>Share</h2>
                                
                                 
                                  <div class="elfsight-app-9e4bce5c-8f17-4608-afa9-15adcf4e478f"></div>
                                  
                                  </div>
                          </div>
                          <div className="disclaimer">
                                <div className="disclaimer-main">
                                    <h2>Book Tour</h2>
                               
                                    </div>
                            </div>


                        </div>
                        
                    </div>
                </div>
            </div>
         
        </div>
        
    </DetailsLayout>             
    );
};

export default TourDetails;